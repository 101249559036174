.answer-item {
    width: calc(100% - 40px);
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.question-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6BC4E8;
    padding: 10px;
    height: 35px;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 15px;
    overflow: hidden;
}

.question-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 2px solid #6BC4E8;
}

.question-row:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
}


.question-row::after:last-child {
    display: none;
}

.question-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #DAF0F9;
    height: 50px;
    text-align: left;
    gap: 10px;
    padding: 10px 20px;
    width: calc(100% - 40px);
    border-radius: 8px;
}

.question-answer,
.inspector-title {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.question-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.question-section {
    display: flex;
    flex-direction: column;
    gap: 15px; 
}

.staff-answer {
    display: flex;
    background-color: #6BC4E8;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    font-size: 16px;
    border: none;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-drag: none;
}

.inspector-button {
    display: flex;
    background-color: #fff;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border: none;
    box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    color: #6BC4E8;
    font-weight: bold;
    align-items: center;
    justify-content: center;
}

.inspector-button.selected {
    background-color: #6BC4E8;
    color: #fff;
}

.inspector-answer {
    display: flex;
    gap: 10px;
}

.question-comment {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
}

.question-comment:last-of-type {
    margin-bottom: 0px;
}

.comment__white-space {
    padding: 10px;
    width: 100%;
}

.question-comment::before {
    position: absolute;
    left: 0px;
    top: 10%;
    content: '';
    width: 1px;
    height: 80%;
    background-color: #dde4eb;
    transition: .3s ease-in-out;
}

.question-desciption__button {
    border: none;
    background-color: #6BC4E8;
    color: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
}

.question-desciption__button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px #319fcb inset;
}

.question-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.inspector-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #DAF0F9;
    height: 50px;
    padding: 10px 20px;
    width: calc(100% - 40px);
    border-radius: 8px;
}

.inspector__input {
    font-family: inherit;
    width: calc(100% - 40px);
    border-radius: 8px;
    resize: none;
    padding: 10px 20px;
    margin-top: 10px;
    height: 10px;
    border: none;
    line-height: 1.5;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    font-size: 14px;
}

.inspector__input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
}

.inspector__input::placeholder {
    font-family: inherit;
    font-size: 14px;
    color: #000;
}

.empty-answer {
    display: flex;
    background-color: #fff;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    font-size: 16px;
    border: none;
    box-shadow: 0px 0px 0px 2cap #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    color: #6BC4E8;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-drag: none
}

.description-popup {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #E1F3FA;
    border-radius: 10px;
    gap: 10px;
    position: absolute;
    top: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 666;
    height: fit-content;
    max-width: 800px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

.description-popup__row {
    display: grid;
    grid-template-columns: 50px 1fr;
    width: 100%;
}

.description-popup__text-block {
    background-color: #fff;
    padding: 20px;
    text-align: left;
    line-height: 1.4;
}

.description-popup__letter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    border-radius: 10px 0px 0px 10px;
    background-color: #6BC4E8;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
}

#inspector-section {
    border: 5px solid rgba(107, 196, 232, 1);
    border-radius: 13px;
}

#inspector-section .question-title {
    padding: 10px 10px;
    width: calc(100% - 20px);
}

#inspector-section .question-comment::before {
    display: none;
}

@media screen and (max-width: 769px) {
    .answer-item {
        padding: 10px;
        width: calc(100% - 20px);
    }

    .question-comment {
        padding: 15px;
    }

    .question-title {
        padding: 10px 15px;
        width: calc(100% - 30px);
    }

    #inspector-section .question-comment {
        padding: 15px 10px;
    }
    #inspqz-span {
        display: none;
    }

    .inspector-section {
        flex-direction: column;
        height: auto;
        gap: 10px;
    }

    .question-desciption__button {
        display: none;
    }
}