.user-info__div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    max-width: 930px;
    width: calc(100% - 50px);
    position: relative;
}

.user__profile__hr-block {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 15px;
    width: calc(100% - 70px);
    max-width: 900px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.user__profile__hr-block button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    background-color: #6BC4E8;
    color: #fff;
}

@media screen and (max-width: 769px) {
    .user-info__div {
        flex-direction: column;
        max-width: 739px;
        width: calc(100% - 20px);
    }

    .user__profile__hr-block {
        width: calc(100% - 40px);
        max-width: 739px;
        padding: 10px;
    }

    .user__profile__hr-block button {
        border-radius: 10px;
    }
}