.quick-actions__div {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.quick-actions__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    padding: 0px 15px;
    border: none;
    border-radius: 20px;
    background-color: #6BC4E8;
    color: #fff;
    text-wrap: nowrap;
    transition: .3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.quick-actions__button:hover:disabled {
    cursor: default;
}

.quick-actions__button:disabled {
    opacity: .5;
}

.quick-actions__button img {
    width: 35px;
    height: 35px;
    transition: .3s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
}

.quick-actions__button:hover {
    cursor: pointer;
}

.button-tooltip {
    display: none;
    transition: .3s ease-in-out;
}

.quick-actions__button:hover .button-tooltip {
    display: block;
    padding: 5px 5px 0 5px;
}


@media screen and (max-width: 426px) {
    .quick-actions__img {
        width: 30px !important;
        height: 30px !important;
    }
}


@media screen and (max-width: 769px) {
    .quick-actions__button {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        border: none;
        font-size: 14px;
        background-color: #6BC4E8;
        color: #fff;
    }

    .quick-actions__div {
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        max-width: 900px;
        border-radius: 20px;
        background-color: #fff;
        gap: 15px;
    }
}