.header {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    z-index: 7777;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header__logo {
    height: 40px;
}

.header__logo:hover {
    cursor: pointer;
}

.header__menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    background-position: center;
}

.header__menu-button:hover {
    cursor: pointer;
}

.header__menu-button_active {
    width: 42px;
    height: 21px;
    margin-right: 14px;
    background-image: url(../../utils/images/menu_button_active.svg);
}

.menu-container {
    position: absolute;
    right: 300px;
    top: 60px;
}

.header__support {
    width: 30px;
    height: 30px;
    background-position: center;
    background-size:  30px;
    background-repeat: no-repeat;
    background-image: url(../../utils/images/support.png);
}

.header__button {
    background-color: transparent;
    border: none;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
    height: 25px;
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.header__block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}

.header__button img {
    width: 24px;
    height: 24px;
}

.header__button:hover {
    cursor: pointer;
    opacity: .7;
}

@media screen and (max-width: 769px) {
    .header__block {
        padding: 0 10px;
    }
}