.quiz__statusbar__container {
    max-width: 930px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.quiz__statusbar {
    display: flex;
    max-width: 930px;
    width: calc(100% - 30px);
    padding: 15px;
    flex-direction: row;
    border-radius: 10px;
    background-color: #fff;
    transition: .3s linear;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.quiz__statusbar_add {
    display: flex;
    max-width: 930px;
    min-height: 37px;
    gap: 15px;
    width: calc(100% - 40px);
    padding: 15px 20px;
    flex-direction: row;
    border-radius: 10px;
    background-color: #fff;
    transition: .3s linear;
    justify-content: space-between;
    align-items: center;
}

.quiz-profile__text-profile {
    margin-left: 15px;
}

.quiz__statusbar__user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 50%;
}


.quiz__statusbar__name-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.quiz__statusbar__name-block p {
    width: 100%;
}

.quiz__statusbar__date-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 300px;
    width: 50%;
}

.quiz__statusbar__title {
    font-size: 20px;
    line-height: 22px;
    font-weight: normal;
}

.quiz__statusbar__subtitle {
    font-size: 16px;
    line-height: 18px;

}

.quiz__statusbar__date-div {
    width: 50%;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quiz__statusbar__item {
    cursor: pointer;
    transition: .2s ease-in-out;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz__statusbar__item_active {
    background-color: #000;
    color: #fff;
}

.quiz__statusbar__respondent-div {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.quiz__statusbar__respondent-div p {}

.quiz__statusbar__respondent-div p:hover {
    cursor: pointer;
}

.quiz__statusbar__respondent-div span {
    font-weight: normal;
    text-decoration: underline;
}

.quiz__statusbar__respondent-div img {
    width: 20px;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
}

.quiz__statusbar__respondent-div img:hover {
    cursor: pointer;
    opacity: .7;
}

.quiz__statusbar__status-p {
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
}

.quiz__statusbar__status-p span {
    font-weight: normal;
}

.quiz__statusbar__button {
    display: flex;
    font-size: 14px;
    width: 150px;
    height: 40px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.quiz__statusbar__button:hover {
    cursor: pointer;
}

.quiz-profile__text-block {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.quiz-profile__avatar {
    width: 75px;
    height: 75px;
}

.quiz__statusbar__buttons-div {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.quiz-profile__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

.quiz-profile__subtitle {
    color: #000;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.quiz__toggle-show__div {
    display: flex;
    flex-direction: row;
}

.quiz__toggle-show__button {
    padding: 10px;
    user-select: none;
    -webkit-user-drag: none;

    transition: .3s ease-in-out;
}

.quiz__toggle-show__button:hover {
    cursor: pointer;
    opacity: .7;
}

.quiz__toggle-show__button_active {
    padding: 10px;
    user-select: none;
    -webkit-user-drag: none;
    background-color: #dce1e6;
}

.quiz__statusbar__select-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
}

.quiz__statusbar__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.quiz__statusbar__row span {
    margin-left: auto;
}

.quiz__statusbar__delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid #E86B83;
    color: #E86B83;
    background-color: transparent;
    height: 40px;
    width: 150px;
    font-size: 14px;
}

.popup__layout {
    width: 100vw;
    height: 100vh;
    background-color: #0000006b;
    position: fixed;
    top: -52px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    padding-bottom: 60px;
}

.delete-quiz__popup__body {
    position: relative;
    max-width: 450px;
    width: calc(100% - 100px);
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    display: flex;
    gap: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete-quiz__popup__agree-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #fff;
    border: none;
    background-color: #E86B83;
    height: 40px;
    width: 150px;
    font-size: 14px;
}

.delete-quiz__popup__deny-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid #6BC4E8;
    color: #6BC4E8;
    background-color: transparent;
    height: 40px;
    width: 150px;
    font-size: 14px;
}

.delete-quiz__popup__buttons-block {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

@media screen and (max-width: 650px) {
    .quiz__statusbar {
        margin: 0;
        padding: 10px;
        width: calc(100% - 20px);
        flex-direction: column;
        align-items: normal;
        gap: 10px;
    }

    .quiz__statusbar__user-block {
        width: 100%;
    }

    .quiz__statusbar__date-block {
        width: 100%;
    }
}

@media screen and (max-width: 769px) {
    .quiz__statusbar__user-block img {
        width: 50px;
        height: 50px;
    }

    .quiz__statusbar__buttons-div {
        flex-direction: column;
    }

    .quiz__statusbar__title {
        font-size: 18px;
        margin: 0;
    }

    .quiz__statusbar__subtitle {
        font-size: 15px;
    }

    .quiz__statusbar__date__p {
        font-size: 15px;
    }

    .quiz__statusbar_add {
        flex-direction: column;
    }

    .quiz__statusbar__status-p {
        width: 100%;
        font-size: 15px;
        flex-direction: column;
        justify-content: center;
    }

    .quiz__statusbar__respondent-div {
        width: 100%;
        font-size: 15px;
        justify-content: center;
    }

    .quiz__statusbar__status-img {
        display: none;
    }

    .quiz-profile__text-block {
        flex-direction: column;
        text-align: center;
    }

    .quiz-profile__subtitle {
        text-align: center;
        white-space: wrap;
        word-break: normal;
    }

    .quiz-profile__text-profile {
        align-items: center;
        margin: 0px;
    }

    .quiz__statusbar__container {
        gap: 8px;
    }

    .quiz__statusbar_add {
        margin-bottom: 8px;
    }
}