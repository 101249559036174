@font-face {
  font-family: "Circe";
  src: url(./utils/fonts/Circe-Regular.woff) format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circe";
  src: url(./utils/fonts/Circe-Bold.woff) format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Circe";
  src: url(./utils/fonts/Circe-Light.woff) format("woff");
  font-weight: 100;
}


button {
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 10px;
  border: none;
}

::-webkit-scrollbar-thumb {
  background-color: #6BC4E8;
  background-clip: padding-box;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-button:single-button {
  background-color: #fff;
  display: block;
  border-style: dashed;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #6BC4E8 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #6BC4E8 transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

* {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: "Circe";
}

.page-transition__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
  position: relative;
}

a {
  color: #000;
}

span {
  font-size: 14px;
}

p {
  line-height: 1.5;
  font-size: 14px;
}

.body-scroll-lock {
  overflow: hidden;
}


.loading-block {
  display: flex;
  flex-direction: column;
  user-select: none;
  position: fixed;
  top: 45%;
}

body {
  background-color: #E1F3FA;
}

button {
  color: #000;
  transition: .3s ease-in-out;
  font-weight: bold;
}

button:hover {
  cursor: pointer;
  opacity: .7;
}

button:disabled {
  opacity: .7;
}

button:disabled:hover {
  cursor: not-allowed;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 769px) {}