.before-exit-popup__layout {
    width: 100vw;
    height: 100vh;
    background-color: #0000006b;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.before-exit-popup__body {
    position: relative;
    max-width: 450px;
    width: calc(100% - 100px);
    padding: 30px 20px 20px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.before-exit-popup__img {
    width: 75px;
    height: 75px;
}

.before-exit-popup__title {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
}

.before-exit-popup__subtitle {
    font-size: 16px;
    
    max-width: 300px;
    margin-bottom: 75px;
}

.before-exit-popup__button {
    display: flex;
    font-size: 15px;
    margin-top: 20px;
    width: 150px;
    height: 50px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.before-exit-popup__button:hover {
    cursor: pointer;
}

.before-exit-popup__button-div {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.before-exit-popup__button_grey {
    display: flex;
    font-size: 15px;
    margin-top: 20px;
    width: 150px;
    height: 50px;
    border: none;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #6BC4E8;
    box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    transition: .3s ease-in-out;
}

.before-exit-popup__button_grey:hover {
    cursor: pointer;
    background-color: #6BC4E8;
    color: #fff;
}