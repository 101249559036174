.change-respondent-popup__layout {
    width: 100vw;
    height: 100vh;
    background-color: #0000006b;
    position: absolute;
    top: -52px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    padding-bottom: 60px;
}

.change-respondent-popup__body {
    position: relative;
    max-width: 550px;
    width: calc(100% - 100px);
    padding: 30px 20px 20px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.change-respondent-popup__body input {
    margin-top: 25px;
    border: none;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    padding: 10px;
    border-radius: 10px;
    width: calc(100% - 20px);
    height: 36px;
    font-size: 15px;
    background-color: #fff;
}

.change-respondent-popup__body input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
}

.change-respondent-popup__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    gap: 5px;
}

.change-respondent-popup__img {
    width: 75px;
    height: 75px;
}

.change-respondent-popup__title {
    top: 38px;
    font-size: 16px;
    font-weight: normal;
}

.change-respondent-popup__subtitle {
    font-size: 16px;
    
    max-width: 300px;
}

.change-respondent-popup__button {
    display: flex;
    font-size: 14px;
    margin-top: 15px;
    width: 150px;
    height: 40px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.change-respondent-popup__button:hover {
    cursor: pointer;
}

.change-respondent__item {
    gap: 10px;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    transition: .3s ease-in-out;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
}

.change-respondent__item p {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.change-respondent__item_selected {
    gap: 10px;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    transition: .3s ease-in-out;
    box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
}

.change-respondent__item_selected p {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.change-respondent__item:hover {
    cursor: pointer;
    opacity: .7;
}

.change-respondent__item_respondent__item_selected:hover {
    cursor: pointer;
    opacity: .7; 
}

.change-respondent-popup__close-button {
    align-self: flex-end;
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.change-respondent-popup__close-button img {
    width: 30px;
    height: 30px;
}

.change-respondent-popup__close-button img:hover {
    cursor: pointer;
}

.change-respondent-popup__title-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 769px) {
    .change-respondent-popup__close-button img {
        width: 25px;
        height: 25px;
    }

    .change-respondent-popup__close-button {
        width: 25px;
        height: 25px;
    }

}