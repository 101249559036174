.report-module__div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
    max-width: 512px;
    width: calc(100% - 20px);
    gap: 15px;
    border-radius: 25px;
    background-color: #fff;
}

.report-module__div form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.report-module__div button {
    display: flex;
    border-radius: 10px;
    border: none;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    background-color: #6BC4E8;
    width: 150px;
    height: 50px;
}

.report-module__div input {
    padding: 5px;
}

.report-module__select-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    align-items: center;
}

.report-module__select-row p {
    width: 50%;
    text-align: left;
}

.report-module__div select {
    padding: 5px;
    border-radius: 10px;
}


.report-module__div button:disabled {
    opacity: 1;
}


.report-module__button-div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    left: 15px;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    border: none;
    background-color: transparent;
    font-size: 16px;
}

.report-module__button-div:hover {
    cursor: pointer;
    opacity: .7;
}

.report-module__div svg {
    align-self: flex-end;
    cursor: pointer;
}