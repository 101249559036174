.methodology-page__div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    width: calc(100% - 70px);
    max-width: 900px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px
}

.methodology-page__div {
    max-width: 900px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #333;
    line-height: 1.6;
}

.methodology-page__div h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #2c3e50;
    position: relative;
    text-align: center;
}

.methodology-page__div h2::after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: #6BC4E8;
    margin: 8px auto 0;
    border-radius: 2px;
}

.methodology-page__div p {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: justify;
}

.methodology-page__div ul {
    list-style: none;
    padding: 0;
}

.methodology-page__div ul li {
    font-size: 14px;
    background: #f8f9fa;
    padding: 12px 16px;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: background 0.3s ease;
}

.methodology-page__border-block {
    background: none;
    border: 4px solid #6BC4E8;
    border-radius: 10px;
    padding: 20px !important;
}

.methodology-page__border-block li {
    background: none;
}

/* .methodology-page__div ul li:hover {
    background: #e9ecef;
} */

.methodology-page__div ul li ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 8px;
    padding-left: 20px;
}

.methodology-page__div ul li ul li {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
    font-size: 14px;
    background: none;
    border-left: 4px solid #6BC4E8;
    margin-bottom: 6px;
}

.process-steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin: 40px 0;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.step__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    border: solid 5px #6BC4E8;
    border-radius: 10px;
}

.step-title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background-color: #6BC4E8;
    margin-bottom: 10px;
}

.step-description {
    font-size: 16px;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;
    padding: 20px 30px;
    text-align: center;
    border-radius: 4px;
    background: #6BC4E8;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s ease;
}

.step:last-child {
    margin-right: 0;
}

.step-description {
    font-size: 14px;
    padding: 15px 0px;
}

@media (max-width: 768px) {
    .methodology-page__div {
        padding: 16px;
    }

    .methodology-page__div h2 {
        font-size: 20px;
    }

    .methodology-page__div p,
    ul li {
        font-size: 14px;
    }
}