.quiz-module__div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 930px;
    width: 100%;
    gap: 20px;
}

.quiz-module__button-div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0;
    user-select: none;
    -webkit-user-drag: none;
    border: none;
    background-color: transparent;
    font-size: 16px;
    z-index: 9999;
}

.quiz-module__button-div p {
    margin: 0;
    font-weight: normal;
}

.quiz-module__button-div:hover {
    cursor: pointer;
}

.quiz-module__form-block {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: column;
    position: relative;
    min-height: 100dvh;
}

.progress-bar {
    width: 100%;
    height: 30px;
    background-color: #F5F5F5;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    position: -webkit-sticky;
    max-width: 930px;
    top: 10px;
    z-index: 9999;
}

.progress-bar__fill {
    height: 100%;
    background-color: #A8E86B;
    transition: width 0.3s ease;
    border-radius: 10px 0 0 10px;
}

.completion-status {
    position: absolute;
    z-index: 888;
    top: 4px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    font-size: 14px;

}

.wrapper {
    display: flex;
    width: 100%;
    gap: 15px;
    flex-direction: column;
    height: 100%;
    position: relative;
}


@media screen and (max-width: 769px) {
    .quiz-module__button-div {
        position: relative;
    }

    .quiz-module__div {
        gap: 10px;
    }
}

@media screen and (max-width: 1025px) {
    .quiz-module__div {
        width: calc(100% - 20px);
    }
}