.auth-block__switcher {
    font-family: inherit;
    
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
}

.auth-block__switcher__item {
    transition: .3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    border-radius: 20px;
}

.auth-block__switcher__item:hover {
    user-select: none;
    cursor: pointer;
}

.auth-block__switcher__item_active {
    background-color: #6BC4E8;
    color: #fff;
    border: none;
}


.auth-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: calc(100vh - 152px);
    max-height: 600px;
}

.auth-block__title{
    font-family: inherit;
    font-size: 20px;
    margin: 0 0 27px 0;
}


.auth-form {
    font-family: inherit;
    
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    font-family: inherit;
    width: calc(100% - 60px);
    max-width: 450px;
    min-height: 350px;
    height: calc(100% - 100px);

    border-radius: 20px;
    justify-content: center;
}

.auth-form__title {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 25px;
    color: #737373;
}

.auth-form__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 70%;
    justify-content: space-around;
}

.auth-form__description {
    font-weight: normal;
    color: #737373;
}

.auth-form__description span {
    color: #000;
}

.auth-form__row:first-of-type {
    margin-top: 0;
}

.auth-form__symbol {
    position: absolute;
    left: 15px;
    top: 65px;
    width: 20px;
    height: 20px;
    opacity: .7;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.auth-form__input {
    height: 50px;
    border: 1px solid #5A6164;
    border-radius: 10px;
    width: 100%;
    font-family: inherit;
    font-size: 20px;
    font-weight: normal;
    background-color: transparent;
    outline: none;
    text-align: center;
}

.auth-form__input1 {
    width: 100%;
    height: 40px;
    font-family: inherit;
    font-weight: bold;
    font-size: 20px;
    background-color: transparent;
    outline: none;
}

.auth-form__input1::placeholder {
    font-family: inherit;
    font-size: 20px;
    color: #5A6164;
}

.auth-form__input::placeholder {
    font-family: inherit;
    font-weight: normal;
    font-size: 20px;
    color: #000;
}

.auth-form__input:focus {
    font-family: inherit;
}

.auth-form__error  {
    color: #cf0404;
    font-weight: normal;
    font-size: 16px;
    margin-top: 20px;
}

.auth-form__button {
    width: 100%;
    height: 62px;
    border: none;
    border-radius: 20px;
    color: #fff;
    background-color: #6BC4E8;
    font-family: inherit;
    font-weight: bold;
    font-size: 16px;
    margin-top: 40px;
}

.auth-form__button:hover {
    cursor: pointer;
}

.auth-form__checkbox {
    margin-top: 20px;
    margin-left: 10px;
    justify-self: center;
    align-self: start;
    font-size: 16px;
}

.auth-form__mobile-div {
    margin-top: 20px;
    font-size: 16px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.auth-form__mobile-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    left: -45px;
    top: 20px;
    background-color: #dce1e6;
    border-radius: 10px;
    padding: 10px 13px 10px 10px;
}

.auth-form__mobile-arrow:hover {
    cursor: pointer;
}

.auth-form__mobile-div p {
    margin-bottom: 10px;
}

@media screen and (max-width: 431px) {
    .auth-form__row {
        width: 90%;
    }

    .auth-form__mobile-arrow {
        left: 0;
        top: 15px;
        padding: 6px 9px 6px 6px;
    }

    .auth-form {
        width: calc(100% - 30px);
    }
}