.hr-module__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
    width: calc(100% - 20px);
    gap: 15px;
}

.hr-module__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.hr-module__header {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    background-color: #F2F2F2;
    gap: 20px;
    padding: 25px;
}

.hr-module__header__subheader {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.hr-module__header__subheader p span {
    font-weight: bold;
}

.hr-module__header__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hr-module__welcome-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    user-select: none;
}

.hr-module__welcome-block h2 {
    font-size: 32px;
    font-weight: normal;
}

.hr-module__welcome-block p {
    font-size: 32px;
    font-weight: normal;
    color: rgba(0, 0, 0, .6);
}

.hr-module__date-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.hr-module__date-circle {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #6BC4E8;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #fff;
    font-weight: bold;
}

.hr-module__date-text {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.hr-module__date-text p {
    line-height: 1.1;
}

.hr-module__main-block {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    padding: 15px;
    gap: 15px;
}

.hr-module__buttons-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.hr-module__s-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #6BC4E8;
    color: #fff;
    padding: 15px 20px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
}

.grey-button {
    background-color: #F2F2F2;
    color: #000;
}

.hr-module__s-button svg {
    width: 24px;
    height: 24px;
}

.hr-module__dashboard-block {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.hr-module__right-dash {
    width: 50%;
    height: 540px;
    max-height: 540px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    color: #fff;
    opacity: .8;
}

.hr-module__except-list__div {
    background-color: #6BC4E8;
    padding: 20px;
    height: calc(50% - 40px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    gap: 10px;
    justify-content: space-between;
}

.hr-module__except-list__div h2 {
    font-size: 16px;
    font-weight: bold;
}

.hr-module__except-list__div p {
    font-size: 14px;
}

.hr-module__left-dash {
    width: calc(50% - 40px);
    height: 500px;
    max-height: 500px;
    background-color: #F2F2F2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: #000;
    flex-direction: column;
    padding: 20px;
}

.hr-module__left-dash h2 {
    font-size: 16px;
    width: 100%;
    text-align: left;
}

.hr-module__empty-warning {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.hr-module__empty-warning p {
    font-size: 14px;
}

.hr-module__bu-list__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 15px;
}

.hr-module__bu-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0px;
    text-align: left;
    max-height: 100%;
    gap: 15px;
}

.hr-module__bu-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 15px 0px;
    text-align: left;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
}

.hr-module__bu-item:last-of-type {
    border: none;
}

.hr-module__bu-item__bu-list {
    width: fit-content;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.scroll-content {
    display: flex;
    flex-direction: column;
    gap: 15px;

}

.hr-module__dash__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.hr-module__dash__header button {
    border: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 5px 10px;
    font-weight: normal;
    background-color: #fff;
}

.hr-module__bu-item__bu-list__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hr-module__bu-item__bu-list__item button {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
}

.hr-module__bu-item__bu-list__item button img {
    height: 20px;
    width: 20px;
}

.hr-module__hr-name {
    width: fit-content;
}

.hr-module__bu-item__add-chain-button {
    width: 100%;
    height: 20px;
    border: none;
    background-color: #fff;
    border-radius: 8px;
    font-weight: normal;
}

.bubpopup__div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 715px;
    width: calc(100% - 50px);
    background-color: #fff;
    border-radius: 20px;
    padding: 15px;
    gap: 15px;
}

.bubpopup__button {
    display: flex;
    font-size: 14px;
    width: 150px;
    height: 50px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.bubpopup__div__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.bubpopup__div__header svg {
    align-self: flex-end;
    cursor: pointer;
}

@media screen and (max-width: 1279px) {
    .hr-module__dashboard-block {
        flex-direction: column;
    }

    .hr-module__left-dash {
        width: calc(100% - 40px);
    }

    .hr-module__right-dash {
        width: 100%;
    }
}