.success-quiz-popup__layout {
    height: 100dvh;
    background-color: #0000006b;
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.success-quiz-popup__body {
    position: relative;
    max-width: 500px;
    width: calc(100% - 100px);
    padding: 30px 20px 20px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.success-quiz-popup__img {
    width: 75px;
    height: 75px;
}

.success-quiz-popup__title {
    font-size: 16px;
    font-weight: normal;
}

.success-quiz-popup__subtitle {
    font-size: 16px;
    max-width: 300px;
}

.success-quiz-popup__button {
    display: flex;
    font-size: 15px;
    width: 150px;
    height: 50px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.success-not-filled-popup__button {
    display: flex;
    font-size: 15px;
    width: 150px;
    height: 50px;
    background-color: transparent;
    color: #6BC4E8;
    border: solid 2px #6BC4E8;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.success-quiz-popup__button:hover {
    cursor: pointer;
}