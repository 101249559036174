.score-module__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: 900px;
    width: calc(100% - 80px);
    border-radius: 20px;
    background-color: #fff;
    gap: 5px;
    margin-top: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.score-module__header {
    width: calc(100% - 40px);
    height: 55px;
    font-size: 14px;
    background-color: #6BC4E8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px
}

.score-module__subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: 30px;
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 0px 15px 0px 25px;
}

.score-module__subheader__col {
    color: #000;
    font-family: inherit;
    font-size: 14px;
}

.score-module__header__col {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.score-module__header__col p {
    font-weight: bold;
    color: #fff;
}

.score-module__score-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6BC4E8;
    border-radius: 15px;
    height: 50px;
    width: 50px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
}

#score-final-empl {
    width: 57%;
    justify-content: space-around;
    justify-items: center;
}

.score-module__p {
    margin: 30px 0 30px 0;
    font-size: 18px;

}

.score-module__title {
    margin-top: 25px;
    font-size: 16px;

}

.score-module__date-block {
    font-size: 14px;
}

.score-module__item:last-of-type {
    margin-bottom: 0;
}

.score-module__item:hover {
    background-color: #f0f2f5;
    cursor: pointer;
}


.score-module__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
    height: 70px;
    border-radius: 8px;
    padding: 0px 15px;
    transition: .3s ease-in-out;
}


.score-module__row {
    display: grid;
    grid-template-columns: 2fr 180px;
    align-items: center;
    width: calc(100% - 30px);
    padding: 0 15px;    
    height: 70px;
    gap: 10px;
}

.score-module__history-block {

}

.score-module__item:last-of-type {
    margin-bottom: 0;
}

.score-module__status-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: left;
    gap: 15px;
}


.score-module__final-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    text-align: left;
    gap: 15px;
    width: 57%;
    justify-items: center;
}

.score-module__button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
    width: 180px;
    height: 50px;
    border-radius: 10px;
    font-size: 14px;
    border: none;
    background-color: #6BC4E8;
    color: #fff;
}

.score-module__loading {
    padding: 50px 0;
    background-color: rgba(255, 255, 255, 0.5);
}

.score-module__item__date {
    font-size: 14px;
    text-wrap: nowrap;
}

@keyframes movingFlare {
    0% {
        left: -30px;
        margin-left: 0px;
    }

    30% {
        left: 110%;
        margin-left: 80px;
    }

    100% {
        left: 110%;
        margin-left: 80px;
    }
}

.score-module__loading::after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0.5) 100%);
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 3s ease-in-out 0.05s infinite;
}

.score-module__button img {
    display: none;
}

.short-text {
    display: none;
}

@media screen and (max-width: 769px) {
    .score-module__block {
        max-width: 739px;
        width: calc(100% - 40px);
        padding: 10px;
    }

    #score-final-empl {
        gap: 30px;
        justify-content: space-around;
        justify-items: center;
    }

    .score-module__title {
        margin-top: 25px;
    }

    .score-module__item {
        padding: 0px;
        width: 100%;
    }

    .score-module__item::before {
        display: none;
    }

    .score-module__button {
        width: 50px;
        height: 35px;
    }

    .score-module__button img {
        display: block;
    }

    .score-module__button p {
        display: none;
    }

    .score-module__status-block {
        width: calc(60% - 5px);
        justify-items: center;
    }


    .score-module__row {
        grid-template-columns: 2fr 50px;
        padding: 0px;
        width: 100%;
    }

    .score-module__item__date {
        margin-left: 5px;
    }

    .full-text {
        display: none;
    }
    .short-text {
        display: inline;
        text-wrap: nowrap;
    }
}

@media screen and (max-width: 965px) {
    .score-module__title {
        margin-top: 25px;
    }
}