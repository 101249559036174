.quiz__create__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 930px;
    width: calc(100% - 30px);
    background-color: #fff;
    border-radius: 20px;
    padding: 15px;
}

.quiz__create__block svg {
    align-self: flex-end;
    cursor: pointer;
}

.quiz__create__div {
    display: flex;
    flex-direction: row;
}

.quiz__create__button {
    padding: 10px;
    user-select: none;
    -webkit-user-drag: none;
    
    transition: .3s ease-in-out;
}

.quiz__create__button:hover {
    cursor: pointer;
    opacity: .7;
}

.quiz__create__button_active {
    padding: 10px;
    user-select: none;
    -webkit-user-drag: none;
    background-color: #f2f2f2;
    border-radius: 5px 5px 0px 0px;
}

.quiz__create__form {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: 900px;
    width: calc(100% - 30px);
    border-radius: 5px;
    background-color: #f2f2f2;
}

input[type='date'] {
    appearance: none;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 15px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 0px 1px #a3a2a2 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #a3a2a2 inset;
    -moz-box-shadow: 0px 0px 0px 1px #a3a2a2 inset;
    margin-left: 5px;
}

input[type='date']:focus {
    outline-color: none;
    box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
}

input[type='date']::-webkit-calendar-picker-indicator {
    height: 20px;
    width: 20px;
}

.quiz__create__p {
    
    user-select: none;
    -webkit-user-drag: none;
}

.quiz__create__p span {
    font-weight: normal;
    text-decoration: underline;
    user-select: none;
    -webkit-user-drag: none;
}

.quiz__create__p span:hover {
    cursor: pointer;
}

.quiz__create__button-s {
    display: flex;
    font-size: 15px;
    width: 150px;
    height: 50px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.change-respondent-popup__button-e {
    display: flex;
    font-size: 14px;
    height: 40px;
    width: 150px;
    background-color: transparent;
    color: #E86B83;
    border: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: 2px solid #E86B83;
    transition: .3s ease-in-out;
}

.change-respondent-popup__button-e:hover {
    cursor: pointer;
    background-color: #E86B83;
    color: #fff;
}

.quiz__create__button-s:hover {
    cursor: pointer;
}

.quiz__create__grid {
    width: calc(100% - 30px);
    padding: 15px;
    background-color: #f2f2f2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    border-radius: 20px;

}

.quiz__create__grid-s {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
}

.quiz__create__grid-item-s_active {
    border: 1px solid #6BC4E8;
    padding: 15px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: .3s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
}

.quiz__create__grid-item-s {
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #CCCCCC;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: .3s ease-in-out;
}

.quiz__create__grid-item-s:hover {
    background-color: #f0f2f5;
    cursor: pointer;
}

.quiz__create__grid-item {
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #cccccc;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.quiz__create__grid-item:hover {
    background-color: #f0f2f5;
    cursor: pointer;
}

.quiz__create__grid-item p {
    
}

.quiz__create__grid-item img {
    width: 50px;
    height: 50px;
}


.quiz__create-popup__layout {
    width: 100vw;
    height: 100vh;
    background-color: #0000006b;
    position: absolute;
    top: 0px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.quiz__create__success {
    color: #007F73;
    font-weight: normal;
}

.quiz__create__error {
    color: #B80000;
    font-weight: normal;
}

.quiz__create-popup__title {
    font-size: 18px;
    font-weight: bold;
}

.quiz__create-popup__p {
    
    padding: 20px 0;
    line-height: 1.5;
}

.quiz__create-popup__p span {
    font-weight: normal;
}

.quiz__create__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.quiz__create__row p {
    width: 70%;
    text-align: left;
}

@media screen and (max-width: 769px) {
    .quiz__create__form {
        width: calc(100% - 20px);
    }
}