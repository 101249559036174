.mailing-module__div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 715px;
    width: calc(100% - 40px);
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    gap: 20px;
    overflow: hidden;
    height: 370px;
}

.mailing-module__title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.mailing-module__div svg {
    cursor: pointer;
    align-self: flex-end;
    cursor: pointer;
}

.mailing-module__select-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.animation__div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.mailing-module__template-edit label {
    align-self: flex-start;
    margin-bottom: 5px;
}

.mailing-module__template-edit {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mailing-module__template-edit textarea {
    resize: none;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #DAF0F9;
    line-height: 1.5;
}

.mailing-module__template-edit textarea:hover {
    border-color: #B5E2F4;
}

.mailing-module__template-edit textarea:focus {
    outline: none;
    border-color: #B5E2F4;
}

.mailing-module__button {
    display: flex;
    border-radius: 10px;
    border: none;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    background-color: #6BC4E8;
    width: 150px;
    height: 50px;
    position: relative;
    overflow: hidden;
    font-size: 16px;
}

.mailing-module__text-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mailing-module__title-row h2 {
    font-size: 16px;
}

.mailing-module__progress-button {
    display: flex;
    border-radius: 10px;
    border: none;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    background-color: #6bc5e8bb;
    width: 150px;
    height: 50px;
    position: relative;
    overflow: hidden;
    font-size: 16px;
}

.mailing-module__progress-button:disabled {
    opacity: 1;
}

.mailing-module__progress-button p {
    position: absolute;
    z-index: 88;
}

.mailing-module__fill-button {
    background-color: #6BC4E8;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 77;
}

.mailing-module__final-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
}