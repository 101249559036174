.user_info__block {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    gap: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


.user_info__text-block {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-height: 75px;
}

.user_info__avatar {
    justify-self: flex-start;
    width: 75px;
    height: 75px;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.user_info__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
}

.user_info__subtitle {
    color: #000;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    width: 100%;
}


.user-info__button-div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -55px;
    left: 0;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: normal;
}

.user-info__button-div:hover {
    cursor: pointer;
}

.user_info__role-block {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 15px;
    right: 15px;
    gap: 5px;
}

.user_info__role-div {
    background-color: #76B947;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    color: #fff;
}

@media screen and (max-width: 426px) {
    /* .user_info__title {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 15px;
    }

    .user_info__subtitle {
        font-size: 13px;
        line-height: 14px;
    }

    .user_info__subtitle:first-of-type {
        margin-bottom: 5px;
    } */

    .user_info__block {
        grid-column-gap: 12px;
    }

}

@media screen and (max-width: 769px) {
    .user_info__block {
        padding: 20px 10px;
        width: calc(100% - 20px);
    }

}