.quick-quiz__title {
    font-size: 18px;
    margin-bottom: 15px;
    
}

.quick-quiz__actions__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    width: calc(100% - 80px);
    background-color: #fff;
    padding: 15px;
    gap: 15px;
    border-radius: 20px;

}

.quick-quiz__actions__buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
}

.quick-quiz__actions__title {
    max-width: 80%;
    margin-bottom: 15px;
    
}

.quick-quiz__actions__button {
    width: 34%;

    height: 75px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    font-size: 15px;
}

.quick-quiz__list__div {
    margin-top: 15px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: 900px;
    width: calc(100% - 80px);
    border-radius: 20px;

    background-color: #fff;
}

.quick-quiz__list__header {
    width: 100%;
    height: 55px;
    font-size: 15px;
    background-color: #6BC4E8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quick-quiz__list__column {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
}