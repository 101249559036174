.drag-drop-zone {
    position: relative;
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 15px 10px;
    text-align: center;
    background-color: #F2F2F2;
    cursor: pointer;
    width: calc(100% - 20px);
}

.drag-drop-zone.dragging {
    background-color: #e6f7ff;
    border-color: #1890ff;
}

.draganddrop__file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.draganddrop__content {
    pointer-events: none;
}

.draganddrop__instruction {
    color: #000;
    font-size: 16px;
}

.draganddrop__file-name {
    font-size: 14px;
    color: #000;
}

.draganddrop__upload-message {
    font-size: 14px;
    color: green;
}

.draganddrop__delete-button {
    z-index: 999;
}

.draganddrop__div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.draganddrop__submit-button {
    background-color: #F2F2F2;
    color: #000;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    padding: 15px 0px;
    width: 175px;
}