.not-center__div {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: 900px;
    width: calc(100% - 80px);
    border-radius: 20px;

    background-color: #fff;
    margin-top: 15px;
}

.not-center__header {
    width: 100%;
    height: 55px;
    font-size: 15px;
    background-color: #6BC4E8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.not-center__header__col {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 25px;
    font-weight: bold;
    color: #fff;
}

.not-center__p-warning {
    padding: 20px 0;
}

.not-center__notif-img {
    width: 15px;
    height: 15px;
}

.not-center__notif-alert {
    width: 30;
    height: 30px;
}

.not-center__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.not-center__item:last-of-type {
    margin-bottom: 0;
}

.not-center__item:hover {
    background-color: #f0f2f5;
    cursor: pointer;
    border-radius: 10px;
}

.not-center__item {
    position: relative;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 10px;
    justify-content: space-between;
    transition: .3s ease-in-out;
    margin-bottom: 5px;
    transition: .3s ease-in-out;
    align-items: center;
}

.not-center__item:last-of-type {
    margin-bottom: 0;
}

@media screen and (max-width: 769px) {
    .not-center__div {
        max-width: 739px;
        width: calc(100% - 40px);
        padding: 10px;
    }

    .not-center__item {
        font-size: 15px;
    }
}