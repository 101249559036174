.final-assessment {
    margin-top: 20px;
}

.final-assessment__row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.final-assessment__question {
    flex: 1;
    font-weight: bold;
}

.final-assessment__answers {
    display: flex;
    gap: 10px;
}


.calibrate-answer {
    display: flex;
    background-color: #fff;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border: none;
    box-shadow: 0px 0px 0px 2cap #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 2px #6BC4E8 inset;
    color: #6BC4E8;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-drag: none;
}

.calibrate-answer_selected {
    display: flex;
    background-color: #48849C;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border: none;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-drag: none;
}

.calibrate-answer_selected-by {
    display: flex;
    background-color: #6BC4E8;
    border-radius: 15px;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border: none;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    user-select: none;
    -webkit-user-drag: none;
}

.question-item__div-se {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 930px;
    width: calc(100% - 30px);
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    gap: 10px;
    justify-content: center;
}

.question-item__row-se {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    background-color: #DAF0F9;
    border-radius: 10px;
}

@media screen and (max-width: 769px) {
    .question-item__row-se {
        flex-direction: column;
        gap: 10px;
    }
}