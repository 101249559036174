.menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 150px ;
    background-color: #fff;
    margin: 0;
    padding: 0;
    text-align: center;
    align-items: center;
    border-radius: 0 0 20px 20px;
    border: 1px solid #d9d9d9;
    border-top: none;
    z-index: 999999;
}

.menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 80px);
    padding: 20px 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    list-style: none;
    color: #fff;
    background-color: #6BC4E8;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid #D9D9D9;
    transition: .3s ease-in-out;
}

.menu__item:hover {
    cursor: pointer;
    opacity: .7;
}